import React from "react";
import * as S from "./estilos";

import ImgBanner from "../../Images/Banner.jpg";
import ImgBannerResp from "../../Images/BannerResp.jpg";
import LogoOpus from "../../Images/LogoOpus.png";
import LogoOpusResp from "../../Images/LogoOpusResp.png";

const Banner = () => {
  return (
    <>
      <S.OutBanner>
        <img src={ImgBanner} className="ImgBanner" alt="" />
        <img src={ImgBannerResp} className="ImgBannerResp" alt="" />
        <S.OutContentBanner>
          <S.TextBanner1>Artista Exclusivo para contratação de Shows</S.TextBanner1>
          <S.OutImageOpusResp>
            <img src={LogoOpusResp} />
          </S.OutImageOpusResp>

          <S.OutLowBanner>
            <S.LeftLowBanner>
              <S.TextBanner2>Vendas de Shows</S.TextBanner2>
              <S.TextBanner3>daniel@bookingmusic.com.br</S.TextBanner3>
              <S.TextBanner3>+55 (17) 99745-4001</S.TextBanner3>
            </S.LeftLowBanner>

            <S.OutImageOpus>
              <img src={LogoOpus} />
            </S.OutImageOpus>

            <S.RightLowBanner>
              <S.TextBanner2>Campanhas Publicitárias</S.TextBanner2>
              <S.TextBanner3>alessandra@danielpromocoes.com.br</S.TextBanner3>
              <S.TextBanner3>+55 (11) 97412.4751</S.TextBanner3>
            </S.RightLowBanner>
          </S.OutLowBanner>
        </S.OutContentBanner>
      </S.OutBanner>
    </>
  );
};

export default Banner;
